$icomoon-font-family: "dap-ikon-font" !default;
$icomoon-font-path: "../../public/fonts/ikoner" !default;

$ikon-sprogvaelger: "\e984";
$ikon-se: "\e983";
$ikon-skift-kunde: "\e982";
$ikon-start-forfra: "\e981";
$ikon-nulindberet: "\e97f";
$ikon-kopier: "\e980";
$ikon-maalflag: "\e97e";
$ikon-ok: "\e97d";
$ikon-tilfoej: "\e97c";
$ikon-print: "\e979";
$ikon-gem: "\e978";
$ikon-slet: "\e977";
$ikon-rediger: "\e976";
$ikon-skatten-2019: "\e975";
$ikon-godt-fra-start: "\e973";
$ikon-skatten-2018: "\e974";
$ikon-gaeld: "\e972";
$ikon-afloeb-draen: "\e92e";
$ikon-afskrivning: "\e92f";
$ikon-asbest-pcb-bly: "\e930";
$ikon-beregn: "\e931";
$ikon-boede: "\e932";
$ikon-boernepasning: "\e933";
$ikon-braendeovne: "\e934";
$ikon-doedsfald: "\e935";
$ikon-ejendomsvurdering: "\e936";
$ikon-excel: "\e937";
$ikon-fjernvarmeunits: "\e938";
$ikon-flytning: "\e939";
$ikon-formue: "\e93a";
$ikon-glasdoere: "\e93b";
$ikon-havearbejde: "\e93c";
$ikon-henvendelser: "\e93d";
$ikon-hjaelp: "\e93e";
$ikon-indenfor-eu: "\e93f";
$ikon-indstillinger: "\e940";
$ikon-information: "\e941";
$ikon-installation-af-varmepumper: "\e942";
$ikon-integrer: "\e943";
$ikon-intelligent-styring: "\e944";
$ikon-isolering-af-gulv: "\e945";
$ikon-isolering-af-tag: "\e946";
$ikon-isolering-af-ydervaegge: "\e947";
$ikon-job: "\e948";
$ikon-kalender: "\e949";
$ikon-kontakt: "\e94a";
$ikon-kontekstuel-lovgivning: "\e94b";
$ikon-ladestik-til-elbiler: "\e94c";
$ikon-loenindkomst: "\e94d";
$ikon-lyt: "\e94e";
$ikon-pdf: "\e94f";
$ikon-pension: "\e950";
$ikon-permeable-belaegninger: "\e951";
$ikon-play: "\e952";
$ikon-problem: "\e954";
$ikon-punktafgifter: "\e955";
$ikon-radonsikring: "\e956";
$ikon-registreringsafgift: "\e957";
$ikon-rengoering: "\e958";
$ikon-renteudgifter-oevrige: "\e959";
$ikon-raadgivere-revisorer: "\e95a";
$ikon-raadgivning: "\e95b";
$ikon-segment: "\e95c";
$ikon-send: "\e95d";
$ikon-servicedesk: "\e95e";
$ikon-skattekort: "\e95f";
$ikon-skorsten: "\e960";
$ikon-solafskaermning: "\e961";
$ikon-starting-a-business: "\e962";
$ikon-su: "\e963";
$ikon-tilslutning-til-bredbaand: "\e964";
$ikon-transparens: "\e965";
$ikon-transport: "\e966";
$ikon-udvendigt-malerarbejde: "\e967";
$ikon-udvidet-indberetning: "\e968";
$ikon-unge: "\e969";
$ikon-upload: "\e96a";
$ikon-varmeanlaeg: "\e96b";
$ikon-ventilation: "\e96c";
$ikon-vindmoeller: "\e96d";
$ikon-vinduespudsning: "\e96e";
$ikon-virksomhed: "\e96f";
$ikon-yderdoere: "\e970";
$ikon-aarsopgoerelsen: "\e971";
$ikon-skatten-2016: "\e92d";
$ikon-menu: "\e92c";
$ikon-dagsbevis: "\e91d";
$ikon-eindkomst: "\e91e";
$ikon-facebook: "\e91f";
$ikon-forskning: "\e920";
$ikon-haandvaerkerfradrag: "\e921";
$ikon-instagram: "\e922";
$ikon-linkedin: "\e923";
$ikon-omregistrering: "\e924";
$ikon-pinterest: "\e925";
$ikon-skatten-2015: "\e926";
$ikon-skatten-2017: "\e927";
$ikon-twitter: "\e928";
$ikon-udland: "\e929";
$ikon-virksomhedsregistrering: "\e92a";
$ikon-youtube: "\e92b";
$ikon-loensumsafgift: "\e91b";
$ikon-cykel: "\e917";
$ikon-dk-flag: "\e918";
$ikon-ekapital: "\e919";
$ikon-kuffert: "\e91a";
$ikon-more: "\e91c";
$ikon-underholdsbidrag: "\e916";
$ikon-afgifter: "\e900";
$ikon-aktier: "\e901";
$ikon-ansatte-og-loen: "\e902";
$ikon-arrow-right: "\e903";
$ikon-arrow-left: "\e97b";
$ikon-arrow-up: "\e986";
$ikon-arrow-down: "\e987";
$ikon-bil: "\e904";
$ikon-borger: "\e905";
$ikon-ejendom: "\e906";
$ikon-erhverv: "\e907";
$ikon-foreninger: "\e908";
$ikon-fradrag: "\e909";
$ikon-gaver: "\e90a";
$ikon-hjaelp-til-tastselv: "\e90b";
$ikon-indkomst-og-skat: "\e90c";
$ikon-ivaerksaetter: "\e90d";
$ikon-log-ind: "\e90e";
$ikon-luk: "\e90f";
$ikon-laan: "\e910";
$ikon-moms: "\e911";
$ikon-personalegoder: "\e912";
$ikon-skattekonto: "\e913";
$ikon-soeg: "\e914";
$ikon-told: "\e915";
$ikon-betalings-id: "\e97a";
$ikon-log-ud: "\e953";

$ikoner: (
  se: $ikon-se,
  skift-kunde: $ikon-skift-kunde,
  start-forfra: $ikon-start-forfra,
  nulindberet: $ikon-nulindberet,
  kopier: $ikon-kopier,
  afgifter: $ikon-afgifter,
  afloeb-draen: $ikon-afloeb-draen,
  afskrivning: $ikon-afskrivning,
  aktier: $ikon-aktier,
  ansatte-og-loen: $ikon-ansatte-og-loen,
  arrow-left: $ikon-arrow-left,
  arrow-right: $ikon-arrow-right,
  arrow-down: $ikon-arrow-down,
  arrow-up: $ikon-arrow-up,
  asbest-pcb-bly: $ikon-asbest-pcb-bly,
  beregn: $ikon-beregn,
  betalings-id: $ikon-betalings-id,
  bil: $ikon-bil,
  boede: $ikon-boede,
  boernepasning: $ikon-boernepasning,
  borger: $ikon-borger,
  braendeovne: $ikon-braendeovne,
  cykel: $ikon-cykel,
  dagsbevis: $ikon-dagsbevis,
  dk-flag: $ikon-dk-flag,
  doedsfald: $ikon-doedsfald,
  eindkomst: $ikon-eindkomst,
  ejendom: $ikon-ejendom,
  ejendomsvurdering: $ikon-ejendomsvurdering,
  ekapital: $ikon-ekapital,
  erhverv: $ikon-erhverv,
  excel: $ikon-excel,
  facebook: $ikon-facebook,
  fjernvarmeunits: $ikon-fjernvarmeunits,
  flytning: $ikon-flytning,
  foreninger: $ikon-foreninger,
  formue: $ikon-formue,
  forskning: $ikon-forskning,
  fradrag: $ikon-fradrag,
  gaeld: $ikon-gaeld,
  gaver: $ikon-gaver,
  gem: $ikon-gem,
  glasdoere: $ikon-glasdoere,
  godt-fra-start: $ikon-godt-fra-start,
  havearbejde: $ikon-havearbejde,
  henvendelser: $ikon-henvendelser,
  hjaelp-til-tastselv: $ikon-hjaelp-til-tastselv,
  hjaelp: $ikon-hjaelp,
  haandvaerkerfradrag: $ikon-haandvaerkerfradrag,
  indenfor-eu: $ikon-indenfor-eu,
  indkomst-og-skat: $ikon-indkomst-og-skat,
  indstillinger: $ikon-indstillinger,
  information: $ikon-information,
  instagram: $ikon-instagram,
  installation-af-varmepumper: $ikon-installation-af-varmepumper,
  integrer: $ikon-integrer,
  intelligent-styring: $ikon-intelligent-styring,
  isolering-af-gulv: $ikon-isolering-af-gulv,
  isolering-af-tag: $ikon-isolering-af-tag,
  isolering-af-ydervaegge: $ikon-isolering-af-ydervaegge,
  ivaerksaetter: $ikon-ivaerksaetter,
  job: $ikon-job,
  kalender: $ikon-kalender,
  kontakt: $ikon-kontakt,
  kontekstuel-lovgivning: $ikon-kontekstuel-lovgivning,
  kuffert: $ikon-kuffert,
  ladestik-til-elbiler: $ikon-ladestik-til-elbiler,
  linkedin: $ikon-linkedin,
  loenindkomst: $ikon-loenindkomst,
  loensumsafgift: $ikon-loensumsafgift,
  log-ind: $ikon-log-ind,
  log-ud: $ikon-log-ud,
  luk: $ikon-luk,
  lyt: $ikon-lyt,
  laan: $ikon-laan,
  menu: $ikon-menu,
  moms: $ikon-moms,
  more: $ikon-more,
  maalflag: $ikon-maalflag,
  ok: $ikon-ok,
  omregistrering: $ikon-omregistrering,
  pdf: $ikon-pdf,
  pension: $ikon-pension,
  permeable-belaegninger: $ikon-permeable-belaegninger,
  personalegoder: $ikon-personalegoder,
  pinterest: $ikon-pinterest,
  play: $ikon-play,
  print: $ikon-print,
  problem: $ikon-problem,
  punktafgifter: $ikon-punktafgifter,
  radonsikring: $ikon-radonsikring,
  rediger: $ikon-rediger,
  registreringsafgift: $ikon-registreringsafgift,
  rengoering: $ikon-rengoering,
  renteudgifter-oevrige: $ikon-renteudgifter-oevrige,
  raadgivere-revisorer: $ikon-raadgivere-revisorer,
  raadgivning: $ikon-raadgivning,
  segment: $ikon-segment,
  send: $ikon-send,
  servicedesk: $ikon-servicedesk,
  skattekonto: $ikon-skattekonto,
  skattekort: $ikon-skattekort,
  skatten-2015: $ikon-skatten-2015,
  skatten-2016: $ikon-skatten-2016,
  skatten-2017: $ikon-skatten-2017,
  skatten-2018: $ikon-skatten-2018,
  skatten-2019: $ikon-skatten-2019,
  skorsten: $ikon-skorsten,
  slet: $ikon-slet,
  soeg: $ikon-soeg,
  solafskaermning: $ikon-solafskaermning,
  starting-a-business: $ikon-starting-a-business,
  su: $ikon-su,
  tilfoej: $ikon-tilfoej,
  tilslutning-til-bredbaand: $ikon-tilslutning-til-bredbaand,
  told: $ikon-told,
  transparens: $ikon-transparens,
  transport: $ikon-transport,
  twitter: $ikon-twitter,
  udland: $ikon-udland,
  udvendigt-malerarbejde: $ikon-udvendigt-malerarbejde,
  udvidet-indberetning: $ikon-udvidet-indberetning,
  underholdsbidrag: $ikon-underholdsbidrag,
  unge: $ikon-unge,
  upload: $ikon-upload,
  varmeanlaeg: $ikon-varmeanlaeg,
  ventilation: $ikon-ventilation,
  vindmoeller: $ikon-vindmoeller,
  vinduespudsning: $ikon-vinduespudsning,
  virksomhed: $ikon-virksomhed,
  virksomhedsregistrering: $ikon-virksomhedsregistrering,
  yderdoere: $ikon-yderdoere,
  youtube: $ikon-youtube,
  aarsopgoerelsen: $ikon-aarsopgoerelsen,
);
