@import "../../styles/2-tools/tools.rem.scss";

@mixin navListHover($distanceToLeft: 0) {
	&:before {
		content: "";
		left: $distanceToLeft;
		width: pxToRem(3);
		height: pxToRem(11);
		display: inline-block;
		background-color: var(--color-primary);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

@mixin hoverFocusShadow($width: 0.2rem, $color: var(--color-primary--60)) {
	outline: none;
	box-shadow: 0 0 0 $width $color;
}

@mixin focusInputField() {
	border: solid var(--border--sm) var(--color-primary);
	outline: solid var(--border--sm) var(--color-white);
	box-shadow: 0 0 0 0.3rem var(--color-primary--60);
}
