@import "../../../../styles/2-tools/tools.button.scss";

.CTALink {
	&___dark {
		@include cta-button(var(--color-white), var(--color-primary));
	}

	&___light {
		@include cta-button(var(--color-primary), var(--color-white));
	}

	&___theme {
		@include cta-button(var(--color-theme-button-contrast), var(--color-theme-button));
	}

	&___logon {
		&:before {
			mask: url("../../../../icons/icon-log-in-light.svg?url");
			content: "";
			mask-repeat: no-repeat;
			mask-position: center;
			background-color: currentColor;
		}
	}

	&___logout {
		&:before {
			mask: url("../../../../icons/icon-log-out-light.svg?url");
			content: "";
			mask-repeat: no-repeat;
			mask-position: center;
			background-color: currentColor;
		}
	}

	&___arrow {
		@include cta-button-arrow;
	}
}
