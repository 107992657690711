@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.typography-mixins.scss";

.Collapse {
	width: 100%;

	&___subCollapse {
		padding: 0;
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--md);
		&:last-of-type {
			margin-bottom: var(--spacing--xl);
		}
	}
}
.Collapse_container {
	overflow: clip;
	overflow-clip-margin: 7px;
}
.Collapse_detailedHeader {
	display: flex;
	justify-content: space-between;
	font-weight: var(--font-weight--semibold);
}

.Collapse_detailedHeading {
	@include heading--sm;

	&___right {
		padding-left: var(--spacing--sm);
	}
}

.Collapse_heading {
	margin: 2rem 0 0.6rem;
	.Collapse___subCollapse & {
		margin: 2rem 0 0.4rem;
	}

	&___minimal {
		&[aria-expanded="false"],
		&[aria-expanded="true"],
		&:hover:not(:focus) {
			border: none;
		}
	}
	:global(.sop-app) & {
		margin: 0;
		margin-top: var(--spacing--sm);
	}
}

.Collapse_appendHeading {
	margin-left: auto;
}

.Collapse_body {
	.Collapse___noBorder & {
		border: none;
	}

	:global(.sop-app) & {
		margin: 0;
		@include mq("sm") {
			margin: 0 var(--spacing--md);
		}
	}
}
.Collapse_body___noBorder {
	border-bottom: none;
}

.Collapse_child {
	display: inline-flex;
	flex-direction: column;
	align-items: baseline;
	margin: var(--spacing--md) 0;

	@include mq("sm") {
		padding-left: var(--spacing--sm);
	}
}

.Collapse_childButton {
	font-size: var(--font-size--sm);
	font-weight: var(--font-weight--bold);
}

.Collapse_grandChildWrapper {
	margin: var(--spacing--md) 0;

	@include mq("sm") {
		padding-left: var(--spacing--sm);
	}
}

.Collapse_grandChildContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.Collapse_grandChild {
	font-size: var(--font-size--xs);
}

.Collapse_textRight {
	padding-left: var(--spacing--2xl);

	@include mq("sm") {
		padding-left: var(--spacing--md);
	}
}
