@import "../../../styles/2-tools/tools.typography-mixins.scss";

.Paragraph {
	@include paragraph;
	&___center {
		text-align: center;
		margin: auto;
	}
	&___muted {
		color: var(--color-primary--40);
	}
	&___suppressed {
		color: var(--color-primary--60);
	}
	&___bold {
		font-weight: var(--font-weight--bold);
	}
	&___semibold {
		font-weight: var(--font-weight--semibold);
	}
	&___small {
		font-size: var(--font-size--xs);
	}
}
