@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.display-mixins.scss";
@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.spacing-mixins.scss";

.Header {
	color: var(--color-white);
	position: relative;
	background-color: var(--color-primary);
	display: flex;
	flex-direction: column;
	@include mq("md") {
		border-bottom: var(--border--3xl) solid var(--color-theme-primary);
		@include side-padding;
	}
	@include mq("md", max) {
		min-height: pxToRem(48);
		border-bottom: var(--border--xl) solid var(--color-theme-primary);
	}
}

.Header_printview {
	display: none;
	@media print {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}

.Header_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	width: 100%;
	max-width: var(--width-xl);
	margin: 0 auto;
	@include mq("md", max) {
		position: relative;
		padding-left: var(--spacing--xs) 0;
	}
	@media print {
		display: none;
	}
}

.Header_logo {
	padding: var(--spacing--lg) 0 var(--spacing--lg) 0;
	@include mq("md", max) {
		padding: 0 0 0 var(--spacing--sm);
	}
}

.Header_navItem {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.Header_link {
	padding-left: var(--spacing--sm);
	padding-right: var(--spacing--sm);
	color: var(--color-white);
	text-decoration: none;
	@include mq("md", max) {
		display: none;
	}
}

.Header_buttonText {
	font-weight: var(--font-weight--normal);
	@include mq("md") {
		margin-left: var(--spacing--sm);
	}
	@include mq("md", max) {
		font-size: var(--font-size--2xs);
	}
}
.Header_buttonIcon {
	height: var(--icon-size--xs);
	width: var(--icon-size--xs);
	&___white {
		fill: var(--color-white);
	}
}

.Header_button {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	border: none;
	flex-direction: row;
	background-color: var(--color-primary);
	height: pxToRem(44);
	margin-right: var(--spacing--2xs);
	@include mq("md") {
		padding: var(--spacing--xs) var(--spacing--sm);
	}
	@include mq("md", max) {
		flex-direction: column;
		padding: 0 var(--spacing--xs);
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 25%;
			height: 50%;
			border-left: var(--border--sm) solid var(--color-primary--60);
		}
	}

	&:hover,
	&:focus {
		box-shadow: 0 0 0 var(--border--md) var(--color-primary--60);
		outline: none;
	}
	&___login {
		background-color: var(--color-light);
		color: var(--color-primary);
		margin-right: 0;
		font-weight: var(--font-weight--semibold);
		&:before {
			border-left: none;
		}
		&:hover,
		&:focus {
			box-shadow: 0 0 0 var(--border--lg) var(--color-white);
			outline: 1px solid var(--color-primary);
		}
	}
	&___menu {
		@include mq("md") {
			display: none;
		}
	}
}

.Header_navLink {
	display: none;
	@include mq("md") {
		display: flex;
		margin-left: auto;
	}
	@include mq("md", max) {
		&___open {
			display: block;
			height: 100%;
			position: absolute;
			margin: auto;
			transition: 0.5s ease-in-out;
		}
	}
}
@include mq("md", max) {
	.Header_backdrop {
		background-color: rgba(0, 0, 0, 0.5);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1;
	}
}
