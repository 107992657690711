@import "../../../../styles/2-tools/tools.button.scss";
@import "../../../../styles/2-tools/tools.shadow-mixins.scss";
.Link {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	&:focus {
		color: var(--color-primary--60);
		@include box-shadow;
	}
	&:hover {
		cursor: pointer;
	}
	&___sm {
		font-size: var(--font-size--xs);
	}

	&___arrow {
		position: relative;
		padding: 0 var(--spacing--base) 0 var(--spacing--xl);
		text-decoration: none;
		display: inline-block;
		color: currentColor;
		outline: none;

		&:hover,
		&:focus {
			color: var(--color-white);
			text-decoration: none;
			background: var(--color-primary--60);

			&::before {
				left: var(--spacing--xs);
			}
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
			width: 8px;
			height: 8px;
			border: solid currentColor;
			border-width: 2px 2px 0 0;
			transition: all 0.3s;
		}
	}
	&___buttonArrow {
		@include cta-button(var(--color-white), var(--color-primary));
		@include cta-button-arrow;
	}
}
