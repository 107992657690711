@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.rem.scss";

.LoginModal {
	width: 100%;
	color: var(--color-black);
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 1fr;
	gap: var(--spacing--lg);

	@include mq("md", max) {
		grid-template-columns: 1fr;
	}
	&___single {
		grid-template-columns: 1fr;
	}
}
.LoginModal_content {
	display: block;
}

.LoginGlobalAlert {
	margin: initial;
}
