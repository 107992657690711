.SearchModal {
	padding: var(--spacing--xs);
	color: var(--color-black);
}

.SearchModal_header {
	padding-bottom: var(--spacing--md);
}

.SearchModal_text {
	margin-top: var(--spacing--md);
	margin-bottom: var(--spacing--md);
	max-height: 50vh;
	overflow-y: auto;
}
