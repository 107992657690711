@import "../../styles/2-tools/tools.behavior.scss";
@import "../../styles/bs-settings/_icon_font_variables.scss";

$icon-width: 4rem;
$cta-button-spacing-vertical: 1.2rem;

@mixin cta-button($color, $background-color) {
	font-weight: var(--font-weight--semibold);
	padding: $cta-button-spacing-vertical var(--spacing--lg) $cta-button-spacing-vertical calc(var(--spacing--lg) + $icon-width);
	display: inline-block;
	flex-direction: row-reverse;
	text-decoration: none;
	outline-offset: 0;
	outline: 1px solid transparent;
	border: none;
	position: relative;
	color: $color;
	background-color: $background-color;

	@include transition {
		transition: all ease var(--trans-time--shorter);
	}

	&:focus-visible {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $color;
		outline-offset: 1px;
		outline: 4px solid $background-color;
		text-decoration: none;
		box-shadow: none;
		background-color: $background-color;
		&:before {
			transform: translateX(0.4rem);
		}
	}
	&:before,
	&:after {
		position: absolute;
		top: 0;
		height: 100%;
	}
	&:before {
		font-family: $icomoon-font-family;
		left: 0;
		width: $icon-width;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: transform 0.3s;
	}

	&:after {
		content: "";
		left: $icon-width;
		width: var(--border--sm);
		border-right: solid var(--border--sm);
	}

	&___logon {
		&:before {
			content: $ikon-log-ind;
		}
	}

	@media print {
		color: black;
		background-color: white;
		border: solid 1px black;
	}
}

@mixin cta-button-arrow() {
	&:before {
		content: $ikon-arrow-right;
		font-size: 0.75rem;
	}
}
$button-box-shadow: 0 0 0 4px;
$button-box-shadow-offset: 0 0 0 1px;

@mixin button-colors-primary($main, $contrast, $background) {
	color: $contrast;
	background: $main;
	border: solid var(--border--sm) $main;
	&:hover,
	&:focus {
		box-shadow: $button-box-shadow-offset $background, $button-box-shadow $main;
	}
}

@mixin button-colors-secondary($main, $background) {
	color: $main;
	background: $background;
	border: solid var(--border--sm) $main;

	&:hover,
	&:focus {
		box-shadow: $button-box-shadow-offset $background, $button-box-shadow $main;
	}
	&___inactive,
	&:disabled {
		border: dotted var(--border--sm) $main;
	}
}

@mixin button-disabled-style() {
	&___inactive,
	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;

		&:hover,
		&:focus {
			transition: none;
			box-shadow: none;
		}
	}
}
