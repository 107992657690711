@import "../../../styles/2-tools/tools.spacing-mixins.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.LanguageSelector {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	&___padded {
		border-bottom: 1px solid transparent;
		max-width: var(--width-md);
		margin-left: auto;
		margin-right: auto;
	}
}

.LanguageSelector_wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: var(--color-white);
	padding-top: var(--spacing--sm);
	padding-bottom: var(--spacing--sm);
	.LanguageSelector___padded & {
		@include side-padding;
		padding-top: var(--spacing--md);
		padding-bottom: var(--spacing--md);
		@include mq("sm", max) {
			padding-left: var(--spacing--lg);
			padding-right: var(--spacing--lg);
		}
	}
}

.LanguageSelector_list {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing--sm);
}
