@import "../../../../styles/2-tools/tools.media-query.scss";

.NavigationCollapse_collapser {
	@include mq("md") {
		display: none;
	}
	$triangle-size: 10px;
	width: 40px;
	background-color: var(--color-white);
	border: var(--border--md) solid transparent;
	position: relative;
	min-height: 40px;

	&::before {
		content: "";
		position: absolute;

		width: $triangle-size;
		height: $triangle-size;

		border-style: solid;
		border-width: 0 3px 3px 0;
		transition: transform ease-in-out 0.3s;
		left: 50%;
		top: calc(50% - $triangle-size / 2);
		transform: translate(-50%) rotate(-45deg);
	}
	&:hover,
	&:focus {
		color: var(--color-primary--60);
		border-color: var(--color-primary--60);
		outline: none;
	}
	&___open {
		background-color: var(--color-bg-subtle);
		&::before {
			transform: translate(-50%) rotate(45deg);
		}
	}
}

.NavigationCollapse_list {
	width: 100%;
}

.NavigationCollapse_item {
	display: none;

	&___open {
		list-style: none;
		flex-direction: column;
		text-align: left;
		display: block;
		border-top: 1px solid var(--color-border--gray);
		padding: var(--spacing--xs) var(--spacing--base);
		background-color: var(--color-bg-subtle);
	}
	@include mq("md") {
		display: none;
	}
}
.NavigationCollapse_link {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: var(--spacing--sm) var(--spacing--sm) var(--spacing--sm) var(--spacing--md);
	min-height: 40px;
	cursor: pointer;
	&:hover,
	&:focus {
		text-decoration: none;
		color: var(--color-primary--60);
		box-shadow: 0 0 0 var(--border--md) var(--color-primary--60);
		outline: none;
	}
	&[aria-current="page"] {
		font-weight: var(--font-weight--bold);
	}
}
