@import "../../styles/2-tools/tools.media-query.scss";

@mixin side-padding {
	@include mq("sm") {
		padding-left: var(--spacing--lg);
		padding-right: var(--spacing--lg);
	}
}
@mixin vertical-margin {
	margin-top: var(--spacing--lg);
	margin-bottom: var(--spacing--lg);
}
