@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.rem.scss";

.Footer {
	background-color: var(--color-primary);
	color: var(--color-white);
	width: 100%;

	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	@include mq("md") {
		border-top: var(--border--3xl) solid var(--color-theme-primary);
	}
	@include mq("md", max) {
		border-top: var(--border--xl) solid var(--color-theme-primary);
	}
	@include mq("sm", max) {
		padding-left: var(--spacing--lg);
		padding-right: var(--spacing--lg);
	}
	@media print {
		display: none;
	}
}

$accomodating-logo-margin-sm: calc(var(--icon-size--lg) - var(--spacing--md));
$accomodating-logo-margin-lg: calc(var(--icon-size--lg) - var(--spacing--lg));

.Footer_logo {
	height: var(--icon-size--lg);
	width: var(--icon-size--lg);
	@include mq("md") {
		margin: $accomodating-logo-margin-sm var(--icon-size--lg) $accomodating-logo-margin-sm 0;
	}
	@include mq("md", max) {
		margin-right: var(--spacing--base);
		margin-bottom: var(--spacing--base);
	}
}

.Footer_heading {
	@include mq("md", max) {
		width: 100%;
		font-size: var(--font-size--xl);
	}
}

.Footer_SocialLinks {
	color: var(--color-light);
}
