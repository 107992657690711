@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.spacing-mixins.scss";

.HeaderSimple {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	background-color: var(--color-primary);
	color: var(--color-white);
	min-height: pxToRem(48);
	@include mq("md") {
		border-bottom: var(--border--3xl) solid var(--color-theme-primary);
		@include side-padding;
	}
	@include mq("md", max) {
		min-height: pxToRem(48);
		border-bottom: var(--border--xl) solid var(--color-theme-primary);
	}
}

.HeaderSimple_wrapper {
	max-width: var(--width-xl);
	width: 100%;
	display: flex;
	align-items: center;
	@include mq("md") {
		min-height: pxToRem(70);
	}
	@include mq("md", max) {
		min-height: pxToRem(44);
	}
}

.HeaderSimple_logo {
	display: flex;
	align-items: center;
	margin-right: auto;

	@include mq("md") {
		height: pxToRem(70);
	}
	@include mq("md", max) {
		height: pxToRem(44);
		padding-left: var(--spacing--lg);
	}
}

.HeaderSimple_closeButton {
	display: flex;
	min-width: auto;
	height: 100%;
	padding-left: 0;
	padding-right: var(--spacing--base);
}

.HeaderSimple_closeIcon {
	height: pxToRem(24);
}

.HeaderSimple_closeText {
	margin-left: var(--spacing--xs);
	font-weight: var(--font-weight--normal);
	@include mq("md", max) {
		display: none;
	}
}

.HeaderSimple_closeButtonContent {
	display: flex;
}
