@import "../../../styles/2-tools/tools.display-mixins";
@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.Logo {
	text-decoration: none;
	color: currentColor;
	&:focus,
	&:hover {
		box-shadow: none;
	}
}

.Logo_heading {
	@include hidden-absolute;
}

.Logo_asset {
	display: flex;
	@include mq("md", max) {
		width: pxToRem(24);
		height: pxToRem(24);
	}
	&___enforceFullLogo {
		@include mq("md", max) {
			width: auto;
			height: auto;
		}
	}
	&___desktop {
		@include mq("md", max) {
			display: none;
		}
	}
	&___mobile {
		@include mq("md") {
			display: none;
		}
		@media print {
			display: none;
		}
	}
}
