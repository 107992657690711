@import "../../../../styles/2-tools/tools.media-query.scss";
@import "../../../../styles/2-tools/tools.display-mixins.scss";
@import "../../../../styles/2-tools/tools.rem.scss";
@import "../../../../styles/2-tools/tools.hover-mixins.scss";

.NavigationItem {
	text-align: left;
	color: currentColor;

	@include mq("md", max) {
		&___header {
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid var(--color-border--gray);
		}
		&___header &:last-child {
			border-bottom: 1px solid var(--color-border--gray);
		}
		&___open {
			background-color: var(--color-bg-subtle);
		}
	}
	@include mq("md") {
		text-align: center;
	}
	&___list {
		text-align: left;
		padding: 0;
	}
}

.NavigationItem_linkWrapper {
	cursor: pointer;
	flex: 1;
}

.NavigationItem_link {
	position: relative;
	color: currentColor;
	text-decoration: none;
	display: block;
	padding: var(--spacing--md);
	margin: 0 var(--spacing--xs) 0 var(--spacing--xs);
	border-bottom: var(--border--sm) solid transparent;
	cursor: pointer;
	&:hover,
	&:focus {
		color: currentColor;
		box-shadow: 0 0 0 var(--border--md) var(--color-primary--60);
		outline: none;
		text-decoration: none;
	}
	@include mq("md", max) {
		flex: 1;
		.NavigationItem___open & {
			font-weight: var(--font-weight--bold);
		}
	}

	&:hover,
	&:focus {
		.NavigationItem___list & {
			@include navListHover;
			color: currentColor;
			text-decoration: none;
		}
	}

	@include mq("md") {
		font-size: var(--font-size--md);
		font-weight: var(--font-weight--normal);
		white-space: nowrap;
	}
	.NavigationItem___list & {
		width: 100%;
		margin: 0;
		padding: var(--spacing--xs) var(--spacing--lg);
		font-size: var(--font-size--md);
		font-weight: var(--font-weight--normal);
		border: none;
		white-space: normal;
	}
}

.NavigationItem_linkName {
	padding: 0 0 var(--spacing--md) 0;
	.NavigationItem___listActive &,
	.NavigationItem___listCurrent & {
		color: currentColor;
		font-weight: var(--font-weight--bold);
		@include navListHover;
	}

	.NavigationItem___headerActive &,
	.NavigationItem___headerCurrent & {
		font-weight: var(--font-weight--bold);
		@include mq("md") {
			border-bottom: var(--border--md) solid var(--color-border--light);
			color: var(--color-light);
		}
	}
}
