@import "../../../styles/2-tools/tools.media-query.scss";

.Alert {
	max-width: var(--width-md);
	margin: var(--spacing--base) auto;
}

.Alert_content {
	padding: var(--spacing--lg);
	margin: auto;
	max-width: var(--width-md);

	&___50 {
		width: 50%;
	}
	&___75 {
		width: 75%;
	}
	&___100 {
		width: 100%;
	}
	@include mq("lg", max) {
		width: 100%;
	}

	> :last-child {
		margin-bottom: 0;
	}

	&___primary {
		border: 2px solid var(--color-primary);
		@media screen {
			background-color: var(--color-hightlight-blue);
			color: var(--color-primary);
		}
	}

	&___warning {
		border: 2px solid var(--color-warning);
		@media screen {
			background-color: var(--color-bg--warning);
			color: var(--color-primary);
		}
	}

	&___danger {
		border: 2px solid var(--color-danger);
		@media screen {
			background-color: var(--color-bg--error);
			color: var(--color-primary);
		}
	}
	@media print {
		background-color: var(--color-white);
		color: var(--color-black);
	}

	a {
		color: inherit;
	}

	&___light {
		background-color: var(--color-white);
		color: var(--color-primary);
	}
}
.Alert_header {
	margin-bottom: var(--spacing--md);
}
