@import "../../../../styles/2-tools/tools.media-query.scss";
@import "../../../../styles/2-tools/tools.spacing-mixins.scss";

.FooterSection {
	border-bottom: 1px solid var(--color-primary--80);
	&:last-child {
		border: none;
	}
	&___identity {
		border: none;
	}
	@include side-padding;
}

.FooterSection_wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	max-width: var(--width-xl);
	width: 100%;
	margin: 0 auto;
	padding: var(--spacing--md) 0;

	.FooterSection___identity & {
		padding-bottom: var(--spacing--sm);
		@include mq("md", max) {
			padding-bottom: var(--spacing--xs);
		}
		@include mq("sm", max) {
			padding-bottom: 0;
		}
	}
}
