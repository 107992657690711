@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.display-mixins.scss";
@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.hover-mixins.scss";

.Navigation {
	&___header {
		@include mq("md") {
			margin: auto;
		}
		@include mq("md", max) {
			background-color: var(--color-white);
			color: var(--color-primary);
			left: 0;
			top: 0;
			bottom: 0;
			position: fixed;
			max-width: 90%;
			width: pxToRem(500);
			z-index: 1000;
			overflow: auto;
		}
	}
	&___list {
		background-color: var(--color-white);
		padding: var(--spacing--lg) 0;
	}
}
.Navigation_burgerOptions {
	@include mq("md") {
		display: none;
	}
	display: flex;
	justify-content: space-between;
	position: relative;
	height: pxToRem(82);

	.Navigation___list & {
		display: none;
	}
}
.Navigation_logo {
	display: flex;
	align-items: center;
	padding: var(--spacing--md);
}
.Navigation_close {
	display: flex;
	align-items: center;
	background-color: var(--color-white);
	border: none;
	padding: var(--spacing--md);
	&:hover,
	&:focus {
		color: currentColor;
		box-shadow: 0 0 0 var(--border--md) var(--color-primary--60);
		outline: none;
		text-decoration: none;
	}
}
.Navigation_heading {
	.Navigation___header & {
		@include visually-hidden;
	}
	.Navigation___list & {
		padding-left: var(--spacing--lg);
		margin-bottom: var(--spacing--xs);
	}
}

.Navigation_list {
	list-style: none;
	flex-direction: column;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	@include mq("md") {
		flex-direction: row;
	}
	.Navigation___list & {
		flex-direction: column;
	}
	@include mq("md", max) {
		border-bottom: 1px solid var(--color-border--gray);
	}
}

.Navigation_extraNavItems {
	@include mq("md") {
		display: none;
	}
}
