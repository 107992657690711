@import "../../../../styles/2-tools/tools.icon-mixins.scss";

.TransportLink {
	display: flex;
	flex-direction: column;
	color: var(--color-primary);
	background-color: var(--color-white);
	text-decoration: none;
	padding: var(--spacing--lg);
	outline: none;
	border: 1px solid transparent;
	&:hover,
	&:focus {
		text-decoration: none;
		box-shadow: none;
		border: 1px solid currentColor;
	}
	&___highlighted {
		background-color: var(--color-primary);
		color: var(--color-white);
		&:hover,
		&:focus {
			color: var(--color-white);
			background: var(--color-primary--60);
		}
	}
}

.TransportLink_heading {
	margin-bottom: var(--spacing--sm);
	font-weight: var(--font-weight--semibold);
}

.TransportLink_text {
	@include arrow-right;
	.TransportLink:hover &,
	.TransportLink:focus & {
		@include arrow-right--hover;
	}
}
