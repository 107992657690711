@import "../../../../styles/bs-settings/icon_font_variables";

.LanguageSelectorItem {
	list-style: none;
	display: flex;
	align-items: center;
	border-right-style: solid;
	border-width: 1px;
	border-right-color: var(--color-bg-subtle);
	padding-right: var(--spacing--sm);
	&:nth-child(-n + 1) {
		border: none;
		font-weight: bold;
	}
	&:last-child {
		border: none;
	}
}

.LanguageSelectorItem_checkmark {
	width: 16px;
	height: 19px;
	margin-right: var(--spacing--xs);
}

.LanguageSelectorItem_globe {
	width: 16px;
	height: 16px;
}
