@mixin arrow-right {
	position: relative;
	padding-left: var(--spacing--lg);
	&:before {
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		border: solid currentColor;
		border-width: 2px 2px 0 0;
		position: absolute;
		left: 0;
		top: 8px;
		transform: rotate(45deg);
		transition: all 0.3s;
	}
}
@mixin arrow-right--hover {
	&:before {
		transform: translate(0.4rem) rotate(45deg);
	}
}

@mixin chevron-expand {
	&::before {
		content: "";
		display: inline-block;
		flex-shrink: 0;
		width: var(--icon-size--2xs);
		height: var(--icon-size--2xs);
		margin-right: 1rem;
		margin-left: 1px;
		border-style: solid;
		border-width: 0 2px 2px 0;
		transform-origin: 75% 75%;
		transition: transform ease-in-out 0.3s;
		transform: translate(-25%, -25%) rotate(45deg);
	}

	&[aria-expanded="true"] {
		&::before {
			transform: translate(-25%, -25%) rotate(225deg);
			margin-bottom: 2px;
		}
	}
}

@mixin chevron-circle {
	&::after {
		content: "";
		color: var(--color-white);
		display: inline-block;
		flex-shrink: 0;
		width: var(--icon-size--2xs);
		height: var(--icon-size--2xs);
		margin-left: var(--spacing--xl);
		margin-right: 1px;
		border-style: solid;
		border-width: 0 var(--border--md) var(--border--md) 0;
		transform-origin: 75% 75%;
		transition: transform ease-in-out 0.3s;
		transform: translate(-25%, -25%) rotate(45deg);
	}

	&[aria-expanded="true"] {
		&::after {
			transform: translate(-25%, -25%) rotate(225deg);
			margin-bottom: 3px;
		}
	}

	&::before {
		content: "";
		position: absolute;
		background-color: currentColor;
		padding: 1em;
		border-radius: 50%;
		right: 1.9rem;
		transform: translateY(-4px);
	}
}
