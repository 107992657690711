@import "../../../styles/2-tools/tools.media-query.scss";
.Breadcrumb {
	background-color: var(--color-white);
}
.Breadcrumb_nav {
	max-width: var(--width-xl);
	margin: 0 auto;
}

.Breadcrumb_list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
}

.Breadcrumb_item {
	display: inline-flex;
	align-items: center;
	margin-right: var(--spacing--md);
	&:not(:first-child)::before {
		display: inline-block;
		content: "";
		height: 0.5rem;
		width: 0.5rem;
		border-top: solid 2px var(--color-black);
		border-right: solid 2px var(--color-black);
		transform: rotate(45deg);
		margin-right: var(--spacing--md);
	}

	&:last-child {
		color: var(--color-primary--60);
	}
}

.Breadcrumb_link {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&:focus {
		color: var(--color-black);
	}
}
